<template>
	<div class="text-center">
		<v-dialog
			v-model="visible"
			persistent
			width="30%"
		>
			<v-card
				color="primary"
				dark
			>
				<v-card-text>
					Loading
					<v-progress-linear
						indeterminate
						color="white"
						class="mb-0"
					></v-progress-linear>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	export default {
		name: "LoadingComponent",
		props: ["visible"],
		data: () =>({

		})
	}
</script>

<style scoped>

</style>
