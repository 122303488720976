import Vue from 'vue'
import './plugins/vuetify'
import Index from './views/Index.vue'
import router from './routes/router'
import store from './stores/store'
import './registerServiceWorker'
import VueIziToast from 'vue-izitoast';
import contenteditable from 'vue-contenteditable'

import 'izitoast/dist/css/iziToast.min.css';
import "material-design-icons/iconfont/material-icons.css";
import "@fortawesome/fontawesome-free/css/all.css";

import './assets/css/main.css'
import './assets/css/animate.css'

import VeeValidate from 'vee-validate';
import Vue2Filters from 'vue2-filters'
import money from 'v-money'
import IdleVue from 'idle-vue'
import VueHtmlToPaper from 'vue-html-to-paper';
import VaInput from './components/form-components/VaInput.vue';
import VaSelect from './components/form-components/VaSelect.vue';
import DataLoading from "@/components/LoadingComponent";
import Moment from 'moment';

import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {
  hideModules: {
    "image": true,
    "code": true
  }
}); // config is optional. more below
import "vue-wysiwyg/dist/vueWysiwyg.css";

Moment.locale( 'de' );
Object.defineProperty( Vue.prototype, '$moment', { value: Moment });

 
Vue.use(VueHtmlToPaper, {});

const eventsHub = new Vue()
 
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 1000 * 60 * 120
})

import {AuthorizeDirective} from './directives/Authorize'
Vue.directive('authorize', AuthorizeDirective)
 
Vue.use(money, {precision: 2, decimal: '.', thousands: ',', prefix: '$ ',})
Vue.use(Vue2Filters)
Vue.use(VeeValidate)
Vue.use(contenteditable)
Vue.use(VueIziToast)
Vue.component('va-input', VaInput)
Vue.component('va-select', VaSelect)
Vue.component("data-loading", DataLoading);
Vue.mixin({
  methods: {
    //Returns value as money
    asMoney:  function (val) {
      if(val == undefined || isNaN(val)){
          return "$ 0.00";
      }
      return "$ " + (parseFloat(val)).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    },
    //Returns value as a percent
    asPercent: function (val) {
      if (val == undefined || isNaN(val)) {
        return '0%';
      }
      return `${val}%`;
    }
  }
})

Vue.config.productionTip = false


const channel = new BroadcastChannel('tab');

channel.postMessage('another-tab');
// note that listener is added after posting the message

channel.addEventListener('message', (msg) => {
  if (msg == 'another-tab' || msg.data == 'another-tab') {
    document.getElementById('app-layout').remove();
  }
});
new Vue({
  router,
  store,
  render: h => h(Index)
}).$mount('#app')
