import axios from 'axios';
import AuthService from '../services/authentication-service'
import LocationService from "../services/location-service.js";
import storageService from "@/services/storage-service";

const client = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  json: true
})

export default {
  uploadClient() {
    const instance = axios.create({
      baseURL: process.env.VUE_APP_UPLOAD_SERVICE_URL,
      json: true,
    });

    return instance;
  },

  async request(method, url, data, headers = {}) {
    let accessToken = storageService.getItem("token");

    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    };

    let coords = {
      latitude: 18,
      longitude: -77,
    };

    // try {
    //   coords = await LocationService.currentCoordinates();
    //   headers = {...headers, 'x-geo-latitude': coords.latitude, 'x-geo-longitude': coords.longitude}
    // } catch(err){
    //   console.log(err)
    // }

    try {
      let req = await client({
        method,
        url: url,
        data: data,
        headers: headers,
        timeout: 6000000,
      });

      return req.data;
    } catch (err) {
      if (err.response && err.response.status === 440) {
        try {
          let res = await AuthService.refreshToken();
          storageService.setItem("token", res.data.token);
          this.request(method, url, data, headers);
          window.location.reload();
          return;
        } catch (e) {
          err = e;
        }
      }

      if (err.response && err.response.status === 403) {
        window.location.href = window.location.origin + "/logout";
        return;
      }

      throw err;
    }
  },

  async getBase64(method, url, data, headers = {}) {
    let accessToken = storageService.getItem("token") || null;

    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    };

    let coords = {
      latitude: 18,
      longitude: -77,
    };

    try {
      coords = await LocationService.currentCoordinates();
      headers = {
        ...headers,
        "x-geo-latitude": coords.latitude,
        "x-geo-longitude": coords.longitude,
      };
    } catch (err) {
      console.log(err);
    }

    try {
      let req = await client({
        method: method,
        url: url,
        data,
        headers: headers,
        // responseType:'arraybuffer',
      });

      return req.data;
    } catch (err) {
      if (err.response && err.response.status === 440) {
        try {
          let res = await AuthService.refreshToken();
          storageService.setItem("token", res.data.token);
          this.request(method, url, data, headers);
          window.location.reload();
          return;
        } catch (e) {
          err = e;
        }
      }

      if (err.response && (err.response.status === 403 || err.response.status === 401)) {
        window.location.href = window.location.origin + "/logout";
        return;
      }

      throw err;
    }
  },

  async downloadExcel(url, data = null, headers = {}) {
    let accessToken = storageService.getItem("token");

    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      let req = await client({
        method: "GET",
        url: url,
        data: data,
        headers: headers,
        responseType: "blob", // Important for binary data like Excel files
        timeout: 6000000,
      });

      return req.data;
    } catch (err) {
      if (err.response && err.response.status === 440) {
        try {
          let res = await AuthService.refreshToken();
          storageService.setItem("token", res.data.token);
          this.downloadExcel(url, data, headers);
          window.location.reload();
          return;
        } catch (e) {
          err = e;
        }
      }

      if (err.response && err.response.status === 403) {
        window.location.href = window.location.origin + "/logout";
        return;
      }

      throw err;
    }
  },
};
